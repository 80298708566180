/* General Setup */

.button span {
  display: block;
  position: relative;
}

.button {
  overflow: hidden;
}

.button:hover span::after {
  opacity: 1;
}

.button span::after {
  opacity: 0;
  position: absolute;
  content: '';
  display: block;
}

/* Float up button specific */

.button.up span::after {
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  transform: translate3d(0, 5px, 0);
  transition: transform 300ms ease-out, opacity 300ms ease-out;
}

.button.up:hover span::after {
  transform: translate3d(0, 0, 0);
}

/* Arrow button specific */

.button.tri span::after {
  transition: transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4),
    opacity 300ms ease-out;
}

.button.tri span {
  transition: transform 300ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
  /* transition-delay: 100ms; */
}

.button.tri.sm span::after {
  right: -15px;
  top: 25%;
  border-top: 5px solid transparent;
  border-left-style: solid;
  border-left-width: 10px;
  border-bottom: 5px solid transparent;
  transform: translate3d(-7.5px, 300%, 0);
}

.button.tri.md span::after {
  right: -19px;
  top: 25%;
  border-top: 7px solid transparent;
  border-left-style: solid;
  border-left-width: 14px;
  border-bottom: 7px solid transparent;
  transform: translate3d(-9.5px, 300%, 0);
}

.button.tri.lg span::after {
  right: -25px;
  top: 25%;
  border-top: 10px solid transparent;
  border-left-style: solid;
  border-left-width: 20px;
  transform: translate3d(-12.5px, 300%, 0);
  border-bottom: 10px solid transparent;
}

.button.tri:hover span::after {
  transform: translate3d(0, 0, 0);
}

.button.tri.sm:hover span {
  transform: translate3d(-7.5px, 0, 0);
}

.button.tri.md:hover span {
  transform: translate3d(-9.5px, 0, 0);
}

.button.tri.lg:hover span {
  transform: translate3d(-12.5px, 0, 0);
}
