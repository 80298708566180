/* Mobile and Tablet */
@media (max-width: 1023px) {
  .contentCard {
    /* padding: 1rem 2.5rem; */
  }

  .contentCard h2 {
    margin-bottom: 0.5rem;
  }
}
