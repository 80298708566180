.ctf h1 {
  @apply mb-5 mt-10 text-h4;
}

.ctf h2 {
  @apply mb-5 mt-10 text-h6;
  display: block;
}

.ctf h3 {
  @apply mb-3 mt-6 text-2xl;
}

.ctf h5 {
  @apply text-h7;
}

.ctf p {
  @apply mb-5 text-body2 font-light;
}

.ctf b {
  @apply font-semibold;
}

.ctf > ol {
  @apply text-body2 font-light;
  list-style: decimal;
  padding-left: 30px;
}

.ctf ol p {
  margin-bottom: 0;
}
.ctf ol ol {
  @apply text-body2 font-light;
  list-style: lower-alpha;
  padding-left: 30px;
}

.ctf ol {
  @apply my-4;
}
