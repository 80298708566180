.perk {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.perkImg {
  border-radius: 50%;
}

.perkDescription {
  padding-top: 3.5rem;
}

/* Mobile and Tablet */
@media (max-width: 1023px) {
  .perk {
    padding-bottom: 0;
  }

  .perkImg {
    margin: auto;
  }

  .perkDescription {
    padding-top: 2rem;
  }
}
