/* Mobile first */

.header {
  height: var(--header-height);
}

/* menu icon */

.navicon {
  display: block;
  height: 2px;
  position: relative;
  transition: background 200ms;
  width: 18px;
}

.navicon:before,
.navicon:after {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  /* transition disabled due to "rotate" animation bug in iOS Chrome... Only the most common browser on the common phone... */
  /* transition works perfectly fine on all other iOS browsers i.e. Brave, Safari, Firefox, DuckDuckGo, Opera and even Edge */
  /* Issue details can be found in comments of https://stackoverflow.com/questions/49384466/css-transition-not-working-in-mobile-chrome */
  /* transition: all 200ms ease-out; */
  width: 100%;
}

.navicon:before {
  top: 5px;
}

.navicon:after {
  top: -5px;
}

@media (max-width: 1023px) {
  .top-container {
    justify-content: space-between;
    width: 100%;
    z-index: 10;
  }

  .menu {
    flex-direction: column;
    transition: right 400ms;
    background-color: white;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 110%;
    padding-top: calc(var(--header-height));
  }

  .top-container:has(> .menuBtn:checked) ~ .menu {
    right: 0;
  }

  .menuBtn ~ .menuIcon .navicon {
    transition: all 400ms;
  }

  .menuBtn ~ .menuIcon .navicon:before {
    transition: all 400ms;
  }

  .menuBtn ~ .menuIcon .navicon:after {
    transition: all 400ms;
  }

  .menuBtn:checked ~ .menuIcon .navicon {
    background: transparent;
  }

  .menuBtn:checked ~ .menuIcon .navicon:before {
    transform: rotate(-45deg);
  }

  .menuBtn:checked ~ .menuIcon .navicon:after {
    transform: rotate(45deg);
  }

  .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:before,
  .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:after {
    top: 0;
  }

  .menu .contactButton {
    @apply mt-4;
  }
}

.navMenuTrigger {
  background: transparent !important;
  /* reset transition-colors from ShadCN to avoid extra text color transition on mobile -> desktop resize */
  transition-property: background-color, border-color, text-decoration-color,
    fill, stroke;
}
.navMenuLink,
.navMenuTrigger,
.navMenuLinkInsideTrigger {
  background: transparent !important;
  border-radius: 0 !important;
}

.navMenuTrigger[data-state='open'] + span + .navContent {
  opacity: 1;
}

@media (max-width: 1023px) {
  .top-container:has(> .menuBtn:checked) ~ .navContent {
    display: block;
  }

  .top-container:has(> .menuBtn:checked) {
    position: fixed;
    width: 90vw;
  }
}

.menu .navMenuLink:after,
.menu .navMenuTrigger:after {
  content: '';
  bottom: 6px; /* adjust for extra padding in new design */
  height: 2px;
  right: 0;
  transform: translate3d(0, 5px, 0);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  width: 100%;
  display: block;
  opacity: 0;
  position: absolute;
}

.menu .navMenuLink:hover:after,
.menu .navMenuTrigger:hover:after {
  transform: translateZ(0);
  opacity: 1;
}

.backButtonVisible {
  opacity: 1;
}

.navMenuListSubMenuOpen {
  transform: translateX(-100vw);
}

.navMenuLink:focus-visible,
.navMenuTrigger:focus-visible,
.navMenuLinkInsideTrigger:focus-visible {
  /* outline-color: black; */
  outline-color: black;
}

@media (width >= 64rem) {
  .navMenuListSubMenuOpen {
    transform: unset;
  }

  .navMenuLink:focus-visible,
  .navMenuTrigger:focus-visible,
  .navMenuLinkInsideTrigger:focus-visible {
    outline-color: var(--accent-foreground);
  }
}
