/* Mobile first */
.footer {
  display: grid;
  grid-template-areas:
    'v2logo'
    'left'
    'middle'
    'form'
    'logos';
}

/* SM - Large Mobile - 40em = 640px */
@media (min-width: 40em) {
  .footer {
    grid-template-areas:
      'v2logo v2logo'
      'left middle'
      'form form'
      'logos logos';
  }
}

/* MD - Tablet - 48em = 768px */
@media (min-width: 48em) {
  .footer {
    grid-template-areas:
      'v2logo v2logo'
      'left middle'
      'form form'
      'logos logos';
  }
}

/* LG - Desktop - 64em = 1024px */
@media (min-width: 64em) {
  .footer {
    grid-template-areas:
      'v2logo v2logo v2logo v2logo'
      'left middle form form'
      'logos middle form form';
    grid-template-columns: 3fr 3fr 2fr 2fr;
  }
}
